import { Controller } from "@hotwired/stimulus"
import {Calendar} from "vanilla-calendar-pro";

export default class extends Controller {
  static targets = ["calendar"];
  
  connect() {
    this.calendar = new Calendar(this.calendarTarget, {
      selectedDate: new Date(),
      selectedTheme: "light",
      onSelect: (date) => this.loadMeetings(date),
      onClickDate: (self) => {
        const date = self.context.selectedDates[0]; // Get first selected date
        this.loadMeetings(date);
      }
    });
    this.calendar.init();
  }
  
  loadMeetings(date) {
    console.log("loadMeetings", date);
    try {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      // Ensure date is valid before calling toISOString()
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }
      const formattedDate = date.toISOString().split('T')[0];
      fetch(`/meetings/calendar_meetings?date=${formattedDate}`, {
        headers: { 
          Accept: "text/vnd.turbo-stream.html",
        }
      })
      .then(response => response.text())
      .then(html => {
        if (html) {
          console.log("html", html);
          Turbo.renderStreamMessage(html);
        }
      })
      .catch(error => {
        console.error('Error fetching meetings:', error);
      });
    } catch (error) {
      console.error('Error formatting date:', error);
    }
  }
}
