import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="copy-summary"
export default class extends Controller {
  static values = {
    notes: String,
    actionItems: String, 
    insights: String
  }
  copy() {
    const summary = this.notesValue;
    const actionItems = this.actionItemsValue;
    const insights = this.insightsValue;
    
    const markdownData = `
    ## Summary
    ${summary}

    ## Action Items
    ${actionItems.split('\\n').join('\n')}

    ## Insights 
    ${insights.split('\\n').join('\n')}`;

    navigator.clipboard.writeText(markdownData).then(() => {
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  }
}
